<template>
  <div class="detail">
    <Top v-if="columnList.img_info_list" :img="'/gsmm/api/v1/' + columnList.img_info_list[0].url" :ch="columnList.name" />
    <div class="content">
      <div class="title" v-if="$route.query.detailId">{{detail.title}}</div>
      <div class="title" v-if="$route.query.successId">{{successdetail.title}}</div>
      <Background />
      <div class="time" v-if="$route.query.detailId">
        {{detail.create_time.split('-')[0]+'年'+detail.create_time.split('-')[1]+'月'+detail.create_time.split(' ')[0].split('-')[2]+'日'}}
      </div>
      <div class="time" v-if="$route.query.successId">
        {{successdetail.create_time.split('-')[0]+'年'+successdetail.create_time.split('-')[1]+'月'+successdetail.create_time.split(' ')[0].split('-')[2]+'日'}}
      </div>
      <div class="ql-snow">
        <div class="ql-editor" v-if="$route.query.detailId" v-html="detail.content">{{detail.content}}</div>
        <div class="ql-editor" v-if="$route.query.successId" v-html="successdetail.content">{{successdetail.content}}</div>
      </div>
<!--      <div class="form">-->
<!--        <div class="top">-->
<!--          <el-input placeholder="您的称呼" v-model="applyName" class="elinput"></el-input>-->
<!--          <el-input placeholder="您的邮箱" v-model="applyEmail" class="elinput"></el-input>-->
<!--          <el-input placeholder="您的电话" v-model="applyPhone" class="elinput"></el-input>-->
<!--          <el-input disabled :placeholder="'培训内容：'+detail.title" v-model="textarea2" class="elinput"></el-input>-->
<!--        </div>-->
<!--        <div class="center">-->
<!--          <el-input-->
<!--              type="textarea"-->
<!--              class="textarea"-->
<!--              :autosize="{ minRows: 6, maxRows: 8}"-->
<!--              placeholder="请输入内容"-->
<!--              resize="none"-->
<!--              v-model="applyMessage">-->
<!--          </el-input>-->
<!--        </div>-->
<!--        <a-button class="button" @click="okTo">提交报名</a-button>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import Top from '../components/top.vue'
import Background from '../components/background.vue'
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      // ch:'数字赋能'
      textarea2:'',
      applyName:'',
      applyEmail:'',
      applyPhone:'',
      applyMessage:''
    };
  },
  components: {
    Top,
    Background
  },
  computed: {
    ...mapState(['columnList', 'detail','successdetail'])
  },
  mounted() {
    this.handleColumn(this.$route.query.id)
    if(this.$route.query.successId){
      this.handleDetail1(this.$route.query.successId)
    }else{
      this.handleDetail(this.$route.query.detailId)
    }
  },
  methods: {
    ...mapActions(['handleColumn', 'handleDetail','handleDetail1']),
    async okTo(){
      console.log(this.detail.id);
      console.log(this.applyName,this.applyMessage,this.applyEmail,this.applyPhone);
      let data = {
        apply_email:this.applyEmail,
        apply_name:this.applyName,
        apply_phone:this.applyPhone,
        apply_message:this.applyMessage,
        apply_introduce_id:this.detail.id
      }
      console.log(data);
      const res = await this.request.post('/apply/create',data)
      console.log(res);
      if(res.status===200){
        this.$message.success('提交成功')
      }
    }
  }
};
</script>


<style>
  .top input.el-input__inner{
    border-radius: 108px;
  }
  .center input.el-input__inner{
    border-radius: 108px;
  }
</style>
<style scoped lang='less'>
.detail {
  width: 100%;
  background-image: url("../../assets/bg.png");
  padding-bottom: 50px;
}
.content {
  padding: 10px 20% 20px 20%;
  .title{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .time{
    text-align:center;
    margin-bottom:10px;
    color:#666;
  }
}
.form{
  height: 400px;
  margin-top: 50px;
  .top{
    display: flex;
    .elinput{
      background-color: rgba(255, 255, 255, 1);
      border-color: rgba(228, 228, 228, 1);
      background: inherit;
      box-sizing: border-box;
      margin: 10px;
    }
  }
  .center{
    .textarea{
      background-color: rgba(255, 255, 255, 1);
      border-color: rgba(228, 228, 228, 1);
      background: inherit;
      box-sizing: border-box;
      margin: 10px 10px 30px 10px;
      width: 98%;
    }
  }
  .button{
    color: white;
    background-color:#5fbd74;
    width: 162px;
    height: 45px;
    font-size: 16px;
    font-weight: 250;
    font-style: normal;
    position: absolute;
    left: calc(50% - 81px);
    border-radius: 108px;
  }
}
</style>

